
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/Modal.vue";
import PAutoComplete from "primevue/autocomplete";

import { getUserName } from "@/libs/utils";
import { Employee } from "@/store/employee/types";

const VSpecialistLink = defineComponent({
  name: "VSpecialistLink",
  components: { CModal, PAutoComplete },
  setup() {
    useHead({ title: "Vincular especialista a um usuário do sistema | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id);

    const employees = computed(() => store.state.employee.searchList);

    const form = reactive({ cd_especialista: _id, username: "" });
    const loading = reactive({ submit: false });
    const employeeQuery = ref("");

    function searchEmployees(event?: { query: string }) {
      return store.dispatch("searchEmployees", { search: { name: event?.query } });
    }

    function handleSelectEmployee(event?: { value: Employee }) {
      form.username = event?.value?.username || "";
      employeeQuery.value = getUserName(event?.value.first_name, event?.value.last_name);
    }

    function handleClose() {
      router.back();
    }

    async function onSubmit() {
      loading.submit = true;
      const response = await store.dispatch("linkSpecialist", { form });
      loading.submit = false;

      if (response?.status === 201) {
        store.dispatch("getSpecialists");
        router.replace({ name: "specialist-list" });
      }
    }

    return {
      form,
      employees,
      employeeQuery,
      loading,
      searchEmployees,
      handleSelectEmployee,
      handleClose,
      onSubmit,
      getUserName,
    };
  },
});

export default VSpecialistLink;
